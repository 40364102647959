import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import HeaderMinimalist from '../../../components/header/Minimalist';

import ContactForm from '../../../components/forms/ContactForm';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H1 from '@rotaready/frecl/build/H1';
import H3 from '@rotaready/frecl/build/H3';
import H4 from '@rotaready/frecl/build/H4';
import FlexContainer from '@rotaready/frecl/build/FlexContainer';
import Text from '@rotaready/frecl/build/Text';
import LinkWithArrow from '@rotaready/frecl/build/LinkWithArrow';

const HeroOuterWrapper = styled.div`
  background-color: #052066;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    transform: translate3d(0, -40px, 0);
  }
`;

const HeroInnerWrapper = styled.div`
  background-repeat: no-repeat;

  background-position: center bottom;
  background-size: 100% auto;
  background-image: url(${props => props.backgroundMobile});

  @media (min-width: ${ViewportBreakpoints.phoneUpper}) {
    margin: 0 80px;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    background-position: right top;
    background-size: auto 100%;
    background-image: url(${props => props.backgroundDesktop});
  }
`;

const Hero = styled.div`
  padding: 80px 0 470px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 210px 0 130px 0;
  }
`;

const HeroInnerContainer = styled(FlexContainer)`
  flex-direction: column;
  width: 100%;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    text-align: left;
  }
`;

const HeroTextWrapper = styled.div`
  margin-bottom: 20px;
`;

const HeroBody = styled.div`
  padding: 0 20px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 0;
    width: 40%;
  }
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0 80px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const BodyItem = styled.div`
  flex: 1 1 auto;
  margin-top: 40px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex: 0 1 45%;
    margin: 0;
  }
`;

const TextBlock = styled.div`
  margin-bottom: 20px;
`;

const TextBlockDoubleMargin = styled.div`
  margin-bottom: 40px;
`;

const Page = ({ data }) => {
  return (
    <Body displayHeader={false}>
      <SEO
        title="Rotaready"
        description=""
        url="landing/christmas-2020-offer"
      />

      <HeaderMinimalist />

      <HeroOuterWrapper>
        <ResponsiveContainer horizontalPadding={false}>
          <HeroInnerWrapper
            backgroundDesktop={data.heroBackgroundDesktop.childImageSharp.fixed.src}
            backgroundMobile={data.heroBackgroundMobile.childImageSharp.fixed.src}
          >
            <Hero>
              <HeroInnerContainer>
                <HeroBody>
                  <HeroTextWrapper>
                    <H1 uistyle="white" text="Get Rotaready free for Q1!" />
                  </HeroTextWrapper>

                  <HeroTextWrapper>
                    <H4 uistyle="white">To end a year that has thrown hurdle after hurdle at hospitality and retail businesses, we wanted to give you a little something to get next year off to a better start.</H4>
                  </HeroTextWrapper>
                </HeroBody>
              </HeroInnerContainer>
            </Hero>
          </HeroInnerWrapper>
        </ResponsiveContainer>
      </HeroOuterWrapper>

      <ResponsiveContainer>
        <BodyWrapper>
          <BodyItem>
            <TextBlock>
              <H3 uistyle="brand160" text="That's why we're offering you Rotaready absolutely free until the end of March 2021, if you sign up before the end of 2020!" />
            </TextBlock>

            <TextBlock>
              <Text size="xl" text="And when we say free, we really mean free - we're ditching the set up fee and onboarding fee; as well as what you'd pay for your subscription from the date you sign up until the end of March." />
            </TextBlock>

            <TextBlockDoubleMargin>
              <Text size="xl" text="Just fill in the form and one of our staff scheduling gurus will be in touch to arrange your demo and answer any questions you may have." />
            </TextBlockDoubleMargin>

            <TextBlock>
              <H3 uistyle="brand160" text="Want to know a bit more about us?" />
            </TextBlock>

            <TextBlock>
              <Text size="xl" text="We do seriously good rota software that's purpose built for hospitality and retail businesses! But it's not JUST rota software, we've got you covered when it comes to monitoring staff time, forecasting future demand, perfecting payroll and all things HR." />
            </TextBlock>

            <TextBlock>
              <Text size="xl" text="Plus we have a whole host of free integrations - so from day one you can connect Rotaready to all your existing systems, including EPOS, bookings, training & learning and more." />
            </TextBlock>
          </BodyItem>

          <BodyItem>
            <ContactForm
              title=""
              submitLabel="Get Rotaready free for Q1"
              source="landing_christmas_2020_offer"
              label="rotaready.com/landing/christmas-2020-offer"
              showPhone
              checkboxes={[
                {
                  id: 'marketing',
                  text: 'I\'d like to receive updates, offers and new content from Rotaready by email. You can unsubscribe at any time.',
                  required: false,
                  initial: false,
                },
              ]}
              onSuccess={(
                <>
                  <TextBlock>
                    <Text size="lg" text="Thanks! Someone from the Rotaready team will give you a call as soon as they can." />
                  </TextBlock>

                  <TextBlock>
                    <Text text="While you're here, have you read about why we started Rotaready?" size="lg" />
                  </TextBlock>

                  <TextBlock>
                    <LinkWithArrow
                      direction="right"
                      to="/about"
                      text="See the Rotaready story"
                      render={props => <Link {...props} />}
                    />
                  </TextBlock>

                  <img height="1" width="1" style={{ display: 'none' }} alt="" src="https://px.ads.linkedin.com/collect/?pid=1327793&conversionId=3692305&fmt=gif" />
                </>
              )}
            />
          </BodyItem>
        </BodyWrapper>
      </ResponsiveContainer>

      <ResponsiveContainer>
        <TextBlockDoubleMargin>
          <LinkWithArrow
            direction="right"
            text="Read the terms and conditions"
            render={props => <a {...props} href="/files-static/landing/rotaready-christmas-2020-offer.pdf" download />}
          />
        </TextBlockDoubleMargin>
      </ResponsiveContainer>
    </Body>
  );
};

export default Page;

export const query = graphql`
  query {
    heroBackgroundDesktop: file(relativePath: { eq: "landing/christmas-2020-offer/hero-desktop.png" }) {
      childImageSharp {
        fixed(height: 713, width: 1440, quality: 50) {
          src
        }
      }
    }

    heroBackgroundMobile: file(relativePath: { eq: "landing/christmas-2020-offer/hero-mobile.png" }) {
      childImageSharp {
        fixed(height: 894, width: 750, quality: 50) {
          src
        }
      }
    }
  }
`;
